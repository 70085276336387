<template>
    <div>
        <TestComponent ref="component" :objType="{one: 'constellation', multi: 'constellations'}"
                       :createNotSubmittable="createNotSubmittable" :editNotSubmittable="editNotSubmittable"
                       @edit-show="showEditModal" @create-show="showCreateModal"
                       @delete-submit="submitDeleteModal"
                       @edit-submit="submitEditModal"
                       @create-submit="submitCreateModal"
                       tableTitle="Edit Constellations"
                       :data="constellationList"
                       nameField="constellationname"
                       :formFields='[
                            {name: "constellationname", type:"text", required:true, label: "Name"},
                            {name: "url", type:"text", required:true, label: "URL", placeholder:"constellation.company.com"},
                            {name: "port", type:"text", required:true, label: "Port", placeholder:"9999"},
                       ]'
                       :columns="['constellationname', 'url', 'port', 'location', 'edit']"
                       :headings="{constellationname: 'Constellation Name', url: 'Constellation URL', port: 'Constellation Port', location: 'Location', edit: 'Edit'}"
                       :sortable="['constellationname', 'url', 'port', 'location']"
                       :filterable="['constellationname', 'url', 'port', 'location']"
                       :orderBy="{column: 'constellationname', ascending: true}">
            <div slot="editModalInput">
                <b-row>
                    <b-col sm="8">
                        <label>Street Address</label>
                        <b-form-input @keyup.enter="editEnterButton" :state="state(location.street)" type="text"
                                      id="street" placeholder="Enter street name"
                                      v-model="location.street"></b-form-input>
                    </b-col>
                    <b-col sm="4">
                        <label>City</label>
                        <b-form-input @keyup.enter="editEnterButton" :state="state(location.city)" type="text" id="city"
                                      placeholder="Enter your city" v-model="location.city"></b-form-input>
                    </b-col>
                </b-row>
                <br>
                <b-row>
                    <b-col sm="6">
                        <label>State</label>
                        <b-form-select id="state" :state="state(location.state)" v-model="location.state" :options="states"/>
                    </b-col>
                    <b-col sm="6">
                        <label>Postal Code</label>
                        <b-form-input @keyup.enter="editEnterButton" :state="state(location.postal)" type="number"
                                      id="postal-code" placeholder="Postal Code"
                                      v-model="location.postal"></b-form-input>
                    </b-col>
                </b-row>
            </div>

            <div slot="editModalFooter">
                <router-link
                    :to="{ name: 'Constellation Permissions', params: { constellationId: curConstellation._id }  }">
                    <b-button size="sm" variant="info">Edit Permissions</b-button>
                </router-link>
                <router-link class="ml-2"
                             :to="{ name: 'Microservices', params: { constellationId: curConstellation._id }  }">
                    <b-button size="sm" variant="info">Manage Microservices</b-button>
                </router-link>
            </div>

            <div slot="createModalInput">
                <b-row>
                    <b-col sm="7">
                        <label>Street Address</label>
                        <b-form-input @keyup.enter="createEnterButton()" :state="state(location.street)" type="text"
                                      placeholder="Enter street name" v-model="location.street"></b-form-input>
                    </b-col>
                    <b-col sm="5">
                        <label>City</label>
                        <b-form-input @keyup.enter="createEnterButton()" :state="state(location.city)" type="text"
                                      placeholder="Enter your city" v-model="location.city"></b-form-input>
                    </b-col>
                </b-row>
                <br>
                <b-row>
                    <b-col sm="6">
                        <label>State</label>
                        <b-form-select v-model="location.state" :state="state(location.state)" :options="states"/>
                    </b-col>
                    <b-col sm="6">
                        <label>Postal Code</label>
                        <b-form-input @keyup.enter="createEnterButton()" :state="state(location.postal)" type="number"
                                      placeholder="Postal Code" v-model="location.postal"></b-form-input>
                    </b-col>
                </b-row>
            </div>
        </TestComponent>
    </div>
</template>

<script>
import TestComponent from '../Component.vue'
import constellationStore from "@/store/constellationStore";
import PubSub from "pubsub-js";

export default {
    name: 'Users',
    components: {
        TestComponent
    },
    data: () => {
        return {
            pubsub: null,
            constellationList: [],
            curConstellation: {},
            location: {},
            states: [
                '','Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut',
                'Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam',
                'Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands',
                'Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada',
                'New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota',
                'Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island',
                'South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia',
                'Washington','West Virginia','Wisconsin','Wyoming'
            ]
        }
    },
    created() {
        this.getConstellations();
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    methods: {
        state(input) {
            if (input) {
                return null;
            }
            for (let field in this.location) {
                if (Boolean(this.location[field]) && !input) {
                    return false;
                }
            }
        },
        getConstellations() {
            constellationStore.dispatch('getConstellations').then(response => {
                this.constellationList = response;
                this.pubsub = PubSub.subscribe('updateconstellations', (msg, data) => {
                    this.constellationList = data;
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Constellations couldn't be retrieved: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        showEditModal(constellation) {
            this.curConstellation = constellation;
            this.location = constellation.location || {};
        },
        showCreateModal() {
            this.location = {};
        },
        submitEditModal(constellation) {
            constellation.location = this.location;
            constellationStore.dispatch('putConstellation', constellation).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: 'Constellation Edited',
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Constellation couldn't be edited: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        submitDeleteModal(id, reload = true) {
            constellationStore.dispatch('deleteConstellation', id).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: 'Constellation Deleted',
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Constellation couldn't be deleted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        submitCreateModal(constellation) {
            constellation.location = this.location;
            constellationStore.dispatch('postConstellation', constellation).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: 'Constellation Created',
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Constellation couldn't be created: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        createEnterButton() {
            for (let field in this.location) {
                if (this.state(this.location[field]) === false) {
                    return;
                }
            }
            this.$refs.component.createEnterButton()
        },
        editEnterButton() {
            for (let field in this.location) {
                if (this.state(this.location[field]) === false) {
                    return;
                }
            }
            this.$refs.component.editEnterButton()
        }
    },
    computed: {
        editNotSubmittable() {
            for (let field in this.location) {
                if (this.state(this.location[field]) === false) {
                    return true;
                }
            }
            return false;
        },
        createNotSubmittable() {
            for (let field in this.location) {
                if (this.state(this.location[field]) === false) {
                    return true;
                }
            }
            return false;
        },
    }
}
</script>

<style scoped>

</style>